<template>
    <v-card>
        <v-dialog v-model="dialogDelete" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline red--text text--darken-1" v-text="$t('title.deleteStatus')"></span>
                </v-card-title>
                <v-card-text>
                    <span class="subtitle-2 text--darken-1" v-text="$t('title.deleteStatusReplace')"></span>
                </v-card-text>

                <v-card-text>
                    <status-selector v-model="editStatus.replace" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelEdit">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="red" dark @click="deleteObject">
                        {{ $t('buttons.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card-text v-if="editStatus && dialog">
            <v-card flat>
                <v-card-title>
                    <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="editStatus.name"
                        :label="$t('status.attributes.name')"
                        required
                        color="red"
                    ></v-text-field>
                    <v-text-field
                        v-model="editStatus.label"
                        :label="$t('status.attributes.label')"
                        required
                        color="red"
                    ></v-text-field>
                    <v-textarea
                        v-model="editStatus.description"
                        :label="$t('status.attributes.description')"
                    ></v-textarea>
                    <v-color-picker
                        v-model="editStatus.color"
                        hide-canvas
                        hide-mode-switch
                        show-swatches
                        hide-inputs
                        flat
                        width="100%"
                        height="200"
                        class="mb-4"
                    ></v-color-picker>
                    <v-switch v-model="editStatus.default" :label="$t('status.attributes.default')"></v-switch>
                    <v-switch v-model="editStatus.open" :label="$t('status.attributes.open')"></v-switch>

                    <status-selector :label="$t('status.attributes.next')" v-model="editStatus.next" />

                    <issue-attr-selector
                        :label="$t('status.attributes.mandatoryAttributes')"
                        v-model="editStatus.mandatoryAttributes"
                    />

                    <v-switch v-model="editStatus.releaseNote" :label="$t('status.attributes.releaseNote')"></v-switch>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="cancelEdit">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="success" @click="saveObject">
                        {{ editStatus.id ? $t('buttons.refresh') : $t('buttons.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-card-text>

        <v-card-text v-else>
            <v-data-table
                :headers="headers"
                :items="status"
                sort-by="name"
                :items-per-page="20"
                :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100, 200],
                    'show-current-page': true,
                    'show-first-last-page': true,
                }"
            >
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-btn rounded color="primary" dark @click="dialog = true">
                            {{ $t('buttons.createStatus') }}
                        </v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.color`]="{ item }">
                    <v-avatar :color="item.color" size="36">
                        <v-icon v-if="item.open">mdi-refresh</v-icon>
                        <v-icon v-else>mdi-check</v-icon>
                    </v-avatar>
                </template>

                <template v-slot:[`item.label`]="{ item }">
                    <span :class="item.default ? 'subtitle-2' : ''">{{ item.label }}</span>
                </template>
                <template v-slot:[`item.next`]="{ item }">
                    <labelStatus :value="item.next" color="amber darken-2" />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:[`item.roles`]="{ item }">
                    <labelRoles :value="item.roles" color="teal darken-2" />
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import labelRoles from '@/components/labels/roles';
import labelStatus from '@/components/labels/status';
import statusSelector from '@/components/selectors/multiple/status';
import issueAttrSelector from '@/components/selectors/multiple/issueAttributes';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'settings-status',
    data: () => ({
        dialog: false,
        dialogDelete: false,
        editStatus: {
            color: '#000000',
        },
    }),
    created() {
        this.findStatus();
    },
    components: {
        labelRoles,
        labelStatus,
        statusSelector,
        issueAttrSelector,
    },
    watch: {},
    computed: {
        ...mapGetters(['status']),
        headers() {
            return [
                {
                    text: '',
                    sortable: false,
                    value: 'color',
                    width: 36,
                },
                {
                    text: this.$t('status.attributes.name'),
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('status.attributes.label'),
                    sortable: true,
                    value: 'label',
                },
                {
                    text: this.$t('status.attributes.next'),
                    sortable: false,
                    value: 'next',
                },
                {
                    text: this.$t('status.attributes.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editStatus.id ? this.$t('title.editStatus') : this.$t('title.newStatus');
        },
    },
    methods: {
        ...mapActions(['findStatus', 'createStatus', 'updateStatus', 'deleteStatus']),
        cancelEdit() {
            this.dialog = false;
            this.dialogDelete = false;
            this.editStatus = { color: '#000000' };
        },
        editItem(item) {
            this.dialog = true;
            this.editStatus = Clone(item);
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.editStatus = Clone(item);
        },
        deleteObject() {
            this.deleteStatus(this.editStatus);
            this.cancelEdit();
        },
        saveObject() {
            if (this.editStatus.id) {
                this.updateStatus(this.editStatus);
            } else {
                this.createStatus(this.editStatus);
            }
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
